export const handleScroll = (rightDivRef, setActiveSection) => {
  return () => {
    const rightDiv = rightDivRef.current;
    const scrollTop = rightDiv.scrollTop;
    const sectionElements = document.getElementsByClassName("Section");

    let lastSectionReached = false;

    for (let i = 0; i < sectionElements.length; i++) {
      const sectionElement = sectionElements[i];
      const sectionTop = sectionElement.offsetTop - rightDiv.offsetTop;

      if (scrollTop >= sectionTop) {
        const sectionId = sectionElement.getAttribute("id");
        setActiveSection(sectionId);
        lastSectionReached = i === sectionElements.length - 1;
      }
    }

    if (lastSectionReached) {
      const lastSectionElement = sectionElements[sectionElements.length - 1];
      const lastSectionTop = lastSectionElement.offsetTop - rightDiv.offsetTop;

      if (scrollTop > lastSectionTop) {
        // Keep the last section at the top of RightDiv
        rightDiv.scrollTop = lastSectionTop;
        // Disable scrolling within RightDiv when last section is reached
        rightDiv.style.overflowY = "hidden";
      } else {
        // Enable scrolling within RightDiv for other sections
        rightDiv.style.overflowY = "scroll";
      }
    } else {
      // Enable scrolling within RightDiv for other sections
      rightDiv.style.overflowY = "scroll";
    }
  };
};


export const handleClick = (sectionId, setActiveSection) => {
  return () => {
    setActiveSection(sectionId);
  };
};

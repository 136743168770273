import React, { useState, useEffect, useRef } from "react";
import SectionRenderer from "./SectionRenderer";
import { StyledAccordionItem } from "./TermsStyles";

const AccordionItem = ({ title, elements, activeSection, handleClick }) => {
  const isOpen = activeSection === title.replace(/\s+/g, "-");
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  const toggleAccordion = () => {
    handleClick(isOpen ? null : title.replace(/\s+/g, "-"));
  };

  return (
    <StyledAccordionItem isOpen={isOpen}>
      <div className="AccordionTitle" onClick={toggleAccordion}>
        {title}
      </div>
      <div
        ref={contentRef}
        className="AccordionContent"
        style={{ height }}
      >
        <SectionRenderer
          id={title.replace(/\s+/g, "-")}
          title={title}
          elements={elements}
        />
      </div>
    </StyledAccordionItem>
  );
};

export default AccordionItem;
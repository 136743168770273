const chatData = [
  {
    id: 1,
    avatarSrc: "/assets/img/Avatar1.png",
    name: "Elisa Jim",
    message: "Hi there how are you",
    time: "3:14 pm",
    badgeCount: 1,
  },
  {
    id: 2,
    avatarSrc: "/assets/img/Avatar2.png",
    name: "Richard John",
    message: "Reacted 🤣 to 'funny'",
    time: "3:14 pm",
  },
];

const detailData = [
  {
    id: 1,
    imgSrc: "/assets/img/Detail-img1.png",
    altText: "RT Translation",
    heading: "Real-Time Translation",
    description:
      "Experience the magic of real-time translation! Our chat app breaks down language barriers, creating a global village where conversations flow effortlessly. Connect with people from all corners of the world, and let language never be a hurdle again.",
  },
  {
    id: 2,
    imgSrc: "/assets/img/Detail-img2.png",
    altText: "DB Networking",
    heading: "Domain-Based Networking",
    description:
      "Experience networking with a twist! Within your chosen domains, connect effortlessly with like-minded individuals, while sending friend requests to those outside your domains. Expand your network, and elevate your conversations, all in one platform.",
  },
];

export { chatData, detailData };
import React, { useEffect, useState, useRef } from "react";
import SectionRenderer from "./SectionRenderer";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { sections, firstDivp } from "./AllData";
import { Wrapper } from "../../navbar/NavbarStyles";
import {
  MainDiv,
  MainHead,
  FirstDiv,
  SecondDiv,
  LeftDiv,
  RightDiv,
  Accordion,
} from "./TermsStyles";
import AccordionItem from "./AccordionItem";
import { handleScroll, handleClick } from "./scrollUtils";

const Terms = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeSection, setActiveSection] = useState(null);
  const rightDivRef = useRef(null);
  const leftDivRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const rightDiv = rightDivRef.current;
    const handleScrollInternal = handleScroll(rightDivRef, setActiveSection);

    if (rightDiv) {
      rightDiv.addEventListener("scroll", handleScrollInternal);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      if (rightDiv) {
        rightDiv.removeEventListener("scroll", handleScrollInternal);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (activeSection && leftDivRef.current) {
      const activeElement = leftDivRef.current.querySelector(
        `a[href="#${activeSection}"]`
      );
      if (activeElement) {
        const elementRect = activeElement.getBoundingClientRect();
        const isVisible =
          elementRect.top >= 0 &&
          elementRect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);

        if (!isVisible) {
          let scrollOffset;
          if (elementRect.top < 0) {
            scrollOffset = elementRect.top - 20;
          } else {
            scrollOffset = elementRect.bottom - window.innerHeight + 20;
          }

          window.scrollTo({
            top: window.scrollY + scrollOffset,
            behavior: "smooth",
          });
        }
      }
    }
  }, [activeSection]);

  const handleClickWrapper = (sectionId) => {
    handleClick(sectionId, setActiveSection)();
  };

  return (
    <>
      <Navbar />
      <Wrapper>
        <MainDiv>
          <MainHead>
            <h1>Terms And Conditions</h1>
          </MainHead>
          <FirstDiv>
            <h2>Agreement To Our Legal Terms</h2>
            {firstDivp}
          </FirstDiv>

          {windowWidth >= 900 ? (
            <SecondDiv>
              <LeftDiv ref={leftDivRef}>
                <ul>
                  {sections.map((section) => (
                    <li key={section.title}>
                      <a
                        href={`#${section.title.replace(/\s+/g, "-")}`}
                        className={
                          activeSection === section.title.replace(/\s+/g, "-")
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handleClickWrapper(section.title.replace(/\s+/g, "-"))
                        }
                      >
                        {section.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </LeftDiv>

              <RightDiv ref={rightDivRef}>
                {sections.map((section, index) => (
                  <div
                    key={index}
                    id={section.title.replace(/\s+/g, "-")}
                    className="Section"
                  >
                    <SectionRenderer
                      id={section.title.replace(/\s+/g, "-")}
                      title={section.title}
                      elements={section.elements}
                      windowWidth={windowWidth}
                    />
                  </div>
                ))}
                <div style={{ height: "100vh" }} />
              </RightDiv>
            </SecondDiv>
          ) : (
            <Accordion>
              {sections.map((section, index) => (
                <AccordionItem
                  key={index}
                  title={section.title}
                  elements={section.elements}
                  activeSection={activeSection}
                  handleClick={handleClickWrapper}
                />
              ))}
            </Accordion>
          )}
        </MainDiv>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Terms;

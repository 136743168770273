import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./components/legal-links/privacyPolicy/Privacy";
import TermsAndConditions from "./components/legal-links/terms/Terms";
import EULA from "./components/legal-links/eula/Eula";
import Home from "./components/home/Home";
import NotFound from "./components/notFound/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/eula" element={<EULA />} />
      </Routes>
    </Router>
  );
}

export default App;

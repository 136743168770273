import { styled } from "@mui/system";

export const MainDiv = styled("div")(({ theme }) => ({
  margin: "0 100px 30px 100px",
  [theme.breakpoints.down("lg")]: {
    margin: "0 36px 30px 36px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 50px 30px 50px",
  },
  [theme.breakpoints.down(480)]: {
    margin: "0 40px 30px 40px",
  },
  [theme.breakpoints.down(400)]: {
    margin: "0 20px 30px 20px",
  },
}));

export const MainHead = styled("div")(({ theme }) => ({
  "& h1": {
    textAlign: "center",
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "64px",
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "39px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "32px",
    },
  },
}));

export const FirstDiv = styled("div")(({ theme }) => ({
  "& h2": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "38px",
    margin: "20px 0",
    [theme.breakpoints.down(2000)]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "22px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "18px",
    },
  },
  "& p, & a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "18px",
    color: "#212324",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "14px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "12px",
    },
  },
}));

export const SecondDiv = styled("div")({
  display: "flex",
  marginTop: "50px",
});

export const LeftDiv = styled("div")(({ theme }) => ({
  paddingRight: "40px",
  boxShadow: "8px 0 10px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("lg")]: {
    paddingRight: "15px",
  },
  "& ul": {
    listStyleType: "none",
    marginTop: "10px",
    padding: 0,
  },
  "& li": {
    marginTop: "12px",
    whiteSpace: "nowrap",
  },
  "& a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "22px",
    color: "#212324",
    lineHeight: "28.47px",
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      fontSize: "17px",
    },
    "&.active": {
      color: "#229ee6",
    },
  },
}));

export const RightDiv = styled("div")(({ theme }) => ({
  scrollBehavior: "smooth",
  overflow: "auto",
  paddingLeft: "50px",
  height: "1010px",
  scrollbarWidth: "none",
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "20px",
  },
  "& h2": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "35px",
    margin: 0,
    [theme.breakpoints.down(2000)]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
  },
  "& h3": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "27px",
    margin: "10px 0",
    [theme.breakpoints.down(2000)]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
  },
  "& p, & a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "19px",
    marginTop: "10px",
    color: "black",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
  },
  "& li": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "19px",
    marginTop: 0,
    color: "black",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
  },
}));

export const Accordion = styled("div")({
  margin: "20px 0",
});

export const StyledAccordionItem = styled("div")(({ theme, isOpen }) => ({
  marginBottom: "10px",
  borderRadius: "5px",
  "& .AccordionTitle": {
    padding: "13px",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "23px",
    color: "#212324",
    border: "1px solid #ccc",
    fontFamily: "'PT Sans', Courier, monospace",
    [theme.breakpoints.down(480)]: {
      fontSize: "20px",
      padding: "11px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "17px",
      padding: "9px",
    },
    ...(isOpen && {
      color: "#229EE6",
      border: "1px solid #ccc",
    }),
  },
  "& .AccordionContent": {
    padding: "0 10px 0 10px",
    height: isOpen ? "auto" : "0",
    overflow: "hidden",
    transition: "height 0.3s ease, opacity 0.3s ease",
    opacity: isOpen ? 1 : 0,
  },
  "& h3": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "20px",
    margin: "10px 0",
    [theme.breakpoints.down(480)]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "17px",
    },
  },
  "& p, & li, & a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "10px",
    color: "#212324",
    [theme.breakpoints.down(480)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "11px",
    },
  },
  "& ol": {
    padding: "0 0 0 22px",
  },
  "& p": {
    padding: "0 0 0 6px",
  },
}));

import React from "react";
import { FixedHeightQuestions, Questions } from "./FaqStyles";
import useAnimateOnView from "../useAnimateOnView";

const QuestionItem = ({ question, answer, isfixedheight, id }) => {
  const [questionDivRef, isQuestionDivView] = useAnimateOnView();

  const slideInFromLeft = {
    hidden: { opacity: 0, x: "-100%" },
    visible: { opacity: 1, x: 0 },
  };

  const slideInFromRight = {
    hidden: { opacity: 0, x: "100%" },
    visible: { opacity: 1, x: 0 },
  };

  const animationVariant =
    id === 2 || id === 4 ? slideInFromRight : slideInFromLeft;
  return (
    <>
      {isfixedheight ? (
        <Questions
          ref={questionDivRef}
          initial="hidden"
          animate={isQuestionDivView ? "visible" : "hidden"}
          variants={animationVariant}
          transition={{ duration: 1 }}
        >
          <h3>{question}</h3>
          <p>{answer}</p>
        </Questions>
      ) : (
        <FixedHeightQuestions
          ref={questionDivRef}
          initial="hidden"
          animate={isQuestionDivView ? "visible" : "hidden"}
          variants={animationVariant}
          transition={{ duration: 1 }}
        >
          <h3>{question}</h3>
          <p>{answer}</p>
        </FixedHeightQuestions>
      )}
    </>
  );
};

export default QuestionItem;

import React from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { sections, firstDivp } from "./AllData";
import SectionRenderer from "../terms/SectionRenderer";
import {
  MainDiv,
  MainHead,
  FirstDiv,
  SecondDiv,
} from "./PrivacyStyles";
import { Wrapper } from "../../navbar/NavbarStyles";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <MainDiv>
          <MainHead>
            <h1>Privacy Policy</h1>
          </MainHead>
          <FirstDiv>{firstDivp}</FirstDiv>
          <SecondDiv>
            {sections.map((section, index) => (
              <div key={index}>
                <SectionRenderer
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  elements={section.elements}
                  windowWidth={901}
                />
              </div>
            ))}
          </SecondDiv>
        </MainDiv>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Privacy;

import { styled } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";

export const ServicesWrapper = styled("div")({
  maxWidth: "2400px",
  margin: "0 auto",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
});

export const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "40px 92px 20px 92px",
  display: "flex",
  justifyContent: "space-between",
  gap: "100px",
  [theme.breakpoints.down("xl")]: {
    margin: "38px 76px 20px 76px",
    gap: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "38px 39px 20px 39px",
    gap: "30px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    margin: "30px 0",
    gap: "15px",
    width: "490px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "30px 0",
    width: "400px",
  },
  [theme.breakpoints.down(480)]: {
    margin: "25px 0",
    width: "357px",
  },
  [theme.breakpoints.down(400)]: {
    margin: "18px 0",
    width: "312px",
  },
  [theme.breakpoints.down(358)]: {
    width: "282px",
  },
  [theme.breakpoints.down(353)]: {
    margin: "15px 0",
    width: "284px",
  },
  [theme.breakpoints.down(315)]: {
    width: "243px",
  },
}));

export const LeftDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {
    gap: "25px",
  },
  [theme.breakpoints.down("lg")]: {
    gap: "10px",
  },
  [theme.breakpoints.down("md")]: {
    gap: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
  },
}));

export const LeftFirst = styled(motion.div)(({ theme }) => ({
  backgroundColor: "#229ee6",
  borderRadius: "30px",
  height: "350px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "4px 4px 15px 0 #0000001a",
  [theme.breakpoints.down("xl")]: {
    height: "320px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "305px",
  },
  [theme.breakpoints.down("md")]: {
    height: "290px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "230px",
  },
  [theme.breakpoints.down(400)]: {
    height: "210px",
  },
  [theme.breakpoints.down(315)]: {
    height: "194px",
  },
}));

export const ChatAbout = styled("div")(({ theme }) => ({
  margin: "0 100px",
  [theme.breakpoints.down(1700)]: {
    margin: "0 95px",
  },
  [theme.breakpoints.down("xl")]: {
    margin: "0 59px",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "0 33px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 55px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "0 25px",
  },
  [theme.breakpoints.down(353)]: {
    margin: "0 18px",
  },
  [theme.breakpoints.down(315)]: {
    margin: "0 12px",
  },
}));

export const ChatSection = styled("div")(({ theme }) => ({
  backgroundColor: "#ffffff",
  height: "180px",
  borderRadius: "15px",
  [theme.breakpoints.down("xl")]: {
    height: "145px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "140px",
  },
  [theme.breakpoints.down("md")]: {
    height: "148px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "114px",
  },
  [theme.breakpoints.down(353)]: {
    height: "94px",
  },
}));

export const Chat = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "14px 18px 5px 10px",
  [theme.breakpoints.down(2000)]: {
    padding: "16px 18px 8px 10px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "5px 12px 4px 8px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "7px 9px 4px 8px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "10px 15px 4px 8px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "7px 11px 0 6px",
  },
  [theme.breakpoints.down(480)]: {
    padding: "8px 11px 0 6px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "9px 7px 0 5px",
  },
  [theme.breakpoints.down(353)]: {
    padding: "3px 6px 0 5px",
  },
  [theme.breakpoints.down(315)]: {
    padding: "4px 6px 0 4px",
  },
}));

export const NameMessage = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "5px 0 0 20px",
  [theme.breakpoints.down("xl")]: {
    margin: "4px 0 0 16px",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "5px 0 0 10px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "6px 0 0 12px",
  },
  [theme.breakpoints.down(400)]: {
    margin: "4px 0 0 10px",
  },
  [theme.breakpoints.down(353)]: {
    margin: "4px 0 0 8px",
  },
  [theme.breakpoints.down(315)]: {
    margin: "4px 0 0 6px",
  },
}));

export const Name = styled("h4")(({ badgeCount, theme }) => ({
  margin: 0,
  fontFamily: "PT Sans, sans-serif",
  fontSize: "23px",
  fontWeight: badgeCount ? 700 : 400,
  color: "#212324",
  [theme.breakpoints.down(2000)]: {
    fontSize: "21px",
  },
  [theme.breakpoints.down(1700)]: {
    fontSize: "19px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "17px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "17px",
  },
  [theme.breakpoints.down(480)]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down(353)]: {
    fontSize: "15px",
  },
  [theme.breakpoints.down(315)]: {
    fontSize: "14px",
  },
}));

export const Message = styled("p")(({ theme }) => ({
  margin: "3px 0 0 0",
  fontFamily: "PT Sans, sans-serif",
  fontSize: "18px",
  fontWeight: 400,
  color: "#212324",
  [theme.breakpoints.down(2000)]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down(1700)]: {
    fontSize: "17px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down(353)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(315)]: {
    fontSize: "11px",
  },
}));

export const TimeBadge = styled("div")({
  marginLeft: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: "3px",
});

export const Time = styled("p")(({ badgeCount, theme }) => ({
  margin: 0,
  paddingTop: "5px",
  color: badgeCount ? "#229ee6" : "#212324",
  fontFamily: "PT Sans, sans-serif",
  fontSize: "18px",
  fontWeight: 700,
  [theme.breakpoints.down(2000)]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down(1700)]: {
    fontSize: "17px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down(353)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(315)]: {
    fontSize: "11px",
  },
}));

export const CustomBadge = styled("div")(({ theme }) => ({
  marginTop: "3px",
  backgroundColor: "#229ee6",
  color: "#212324",
  borderRadius: "1000px",
  width: "30px",
  height: "29px",
  fontFamily: "PT Sans, sans-serif",
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  [theme.breakpoints.down(2000)]: {
    width: "29px",
    height: "28px",
    fontSize: "12px",
  },
  [theme.breakpoints.down(1700)]: {
    width: "28px",
    height: "27px",
  },
  [theme.breakpoints.down("xl")]: {
    width: "25px",
    height: "24px",
    fontSize: "11px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "23px",
    height: "22px",
    fontSize: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "25px",
    height: "24px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "18px",
    height: "18px",
    fontSize: "11px",
  },
  [theme.breakpoints.down(480)]: {
    width: "17px",
    height: "17px",
    fontSize: "10px",
  },
  [theme.breakpoints.down(400)]: {
    width: "15px",
    height: "15px",
    fontSize: "9px",
  },
  [theme.breakpoints.down(353)]: {
    width: "13px",
    height: "13px",
    fontSize: "7px",
  },
  [theme.breakpoints.down(315)]: {
    width: "12px",
    height: "12px",
    fontSize: "6px",
  },
}));

export const About = styled("div")(({ theme }) => ({
  fontFamily: "PT Sans, sans-serif",
  fontSize: "23px",
  fontWeight: 400,
  textAlign: "center",
  color: "#ffffff",
  [theme.breakpoints.down(2000)]: {
    fontSize: "21px",
  },
  [theme.breakpoints.down(1700)]: {
    fontSize: "19px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.down(480)]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "11px",
  },
  [theme.breakpoints.down(315)]: {
    fontSize: "10px",
  },
}));

export const ResponsiveAvatar = styled(Avatar)(({ theme }) => ({
  width: 70,
  height: 70,
  [theme.breakpoints.down(2000)]: {
    width: 67,
    height: 67,
  },
  [theme.breakpoints.down(1700)]: {
    width: 65,
    height: 65,
  },
  [theme.breakpoints.down("xl")]: {
    width: 62,
    height: 62,
  },
  [theme.breakpoints.down("lg")]: {
    width: 57,
    height: 57,
  },
  [theme.breakpoints.down("sm")]: {
    width: 47,
    height: 47,
  },
  [theme.breakpoints.down(400)]: {
    width: 43,
    height: 43,
  },
  [theme.breakpoints.down(353)]: {
    width: 41,
    height: 41,
  },
  [theme.breakpoints.down(315)]: {
    width: 39,
    height: 39,
  },
}));

export const LeftSecond = styled(motion.div)(({ theme }) => ({
  backgroundColor: "#f9fdff",
  borderRadius: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 80px",
  gap: "250px",
  boxShadow: "4px 4px 15px 0 #0000001a",
  ...getSharedHeight(theme),
  [theme.breakpoints.between(1536, 2400)]: {
    padding: "0 97px",
    gap: "120px",
  },
  [theme.breakpoints.between(1200, 1536)]: {
    padding: "0 40px",
    gap: "40px",
  },
  [theme.breakpoints.between(990, 1200)]: {
    gap: "50px",
    padding: "0 47px",
  },
  [theme.breakpoints.between(900, 990)]: {
    gap: "21px",
    padding: "0 25px",
  },
  [theme.breakpoints.between(600, 900)]: {
    gap: "61px",
    padding: "0 20px",
  },
  [theme.breakpoints.between(480, 600)]: {
    gap: "50px",
    padding: "0 15px",
  },
  [theme.breakpoints.between(400, 480)]: {
    gap: "11px",
    padding: "0 5px",
  },
  [theme.breakpoints.between(400, 480)]: {
    gap: "11px",
    padding: "0 5px",
  },
  [theme.breakpoints.between(353, 400)]: {
    gap: "16px",
    padding: "0 5px",
  },
  [theme.breakpoints.between(280, 353)]: {
    gap: "17px",
    padding: "0 5px",
  },
}));

export const Detail1 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    margin: "18px 45px",
  },
  [theme.breakpoints.down(1328)]: {
    margin: "18px 0",
  },
  [theme.breakpoints.down("lg")]: {
    margin: 0,
  },
}));

export const DetailImg = styled("div")(({ theme }) => ({
  width: "119px",
  height: "110px",
  [theme.breakpoints.down(1800)]: {
    width: "100px",
    height: "94px",
  },
  [theme.breakpoints.down(1700)]: {
    width: "97px",
    height: "84px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "72px",
    height: "65px",
  },
  [theme.breakpoints.down("md")]: {
    width: "75px",
    height: "75px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70px",
    height: "70px",
  },
  [theme.breakpoints.down(480)]: {
    width: "66px",
    height: "66px",
  },
  [theme.breakpoints.down(400)]: {
    width: "55px",
    height: "52px",
  },
  [theme.breakpoints.down(358)]: {
    width: "50px",
    height: "45px",
  },
  [theme.breakpoints.down(353)]: {
    width: "40px",
    height: "40px",
  },
  "& img": {
    objectFit: "contain",
    width: "100%",
    height: "auto",
  },
}));

export const DetailHeading = styled("div")(({ theme }) => ({
  "& h2": {
    margin: 0,
    fontFamily: "PT Sans, sans-serif",
    fontSize: "41px",
    fontWeight: 700,
    marginTop: "25px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down(1700)]: {
      fontSize: "31px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "29px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "27px",
      marginTop: "12px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
      marginTop: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "27px",
      marginTop: "6px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "25px",
      marginTop: "4px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "18px",
      marginTop: "2px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "16px",
    },
  },
}));

export const DetailText = styled("div")(({ theme }) => ({
  padding: "0 190px",
  [theme.breakpoints.down(2400)]: {
    padding: "0 100px",
  },
  [theme.breakpoints.down(2000)]: {
    padding: "0 77px",
  },
  [theme.breakpoints.down(1720)]: {
    padding: "0 40px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "0 35px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0 8px",
  },
  [theme.breakpoints.down(315)]: {
    padding: "0 2px",
  },
  "& p": {
    textAlign: "center",
    fontFamily: "Inter, sans-serif",
    fontSize: "23px",
    fontWeight: 400,
    margin: 0,
    marginTop: "2px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down(1700)]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "10px",
    },
  },
}));

export const RightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {
    gap: "25px",
  },
  [theme.breakpoints.down("lg")]: {
    gap: "10px",
  },
  [theme.breakpoints.down("md")]: {
    order: -1,
    gap: "12px",
  },
}));

const getSharedHeight = (theme) => ({
  height: "1290px",
  [theme.breakpoints.down(2262)]: {
    height: "1200px",
  },
  [theme.breakpoints.down(2112)]: {
    height: "1110px",
  },
  [theme.breakpoints.down(2025)]: {
    height: "1050px",
  },
  [theme.breakpoints.down(1850)]: {
    height: "960px",
  },
  [theme.breakpoints.down(1705)]: {
    height: "890px",
  },
  [theme.breakpoints.down(1585)]: {
    height: "850px",
  },
  [theme.breakpoints.down(1479)]: {
    height: "800px",
  },
  [theme.breakpoints.down(1392)]: {
    height: "730px",
  },
  [theme.breakpoints.down(1270)]: {
    height: "680px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "660px",
  },
  [theme.breakpoints.down(1080)]: {
    height: "620px",
  },
  [theme.breakpoints.down(1014)]: {
    height: "580px",
  },
  [theme.breakpoints.down(936)]: {
    height: "530px",
  },
  [theme.breakpoints.down("md")]: {
    height: "570px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "510px",
  },
  [theme.breakpoints.down(480)]: {
    height: "436px",
  },
  [theme.breakpoints.down(400)]: {
    height: "380px",
  },
  [theme.breakpoints.down(358)]: {
    height: "350px",
  },
  [theme.breakpoints.down(315)]: {
    height: "296px",
  },
});

export const RightFirst = styled(motion.div)(({ theme }) => ({
  backgroundColor: "#229ee6",
  borderRadius: "30px",
  boxShadow: "4px 4px 15px 0 #0000001a",
  display: "flex",
  flexDirection: "column",
  ...getSharedHeight(theme),
}));

export const EasytalkHeading = styled("div")(({ theme }) => ({
  "& h2": {
    fontFamily: "PT Sans, sans-serif",
    fontSize: "46px",
    fontWeight: 700,
    fontStyle: "italic",
    marginLeft: "30px",
    color: "#212324",
    [theme.breakpoints.down("xl")]: {
      fontSize: "29px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
      textAlign: "center",
      fontSize: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "38px",
      marginTop: "10px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "35px",
      marginTop: "9px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "29px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "21px",
    },
    "& span": {
      color: "#ffffff",
    },
  },
}));

export const EasytalkImg = styled("div")(({ theme }) => ({
  overflow: "hidden",
  marginTop: "10px",
  "& img": {
    objectFit: "contain",
    width: "85%",
    transform: "translateX(8%)",
  },
  [theme.breakpoints.down("md")]: {
    width: "93%",
    transform: "translateX(4%)",
  },
}));

export const RightSecond = styled(motion.div)(({ theme }) => ({
  backgroundColor: "#f9fdff",
  height: "336px",
  borderRadius: "30px",
  boxShadow: "4px 4px 15px 0 #0000001a",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "12px",
  [theme.breakpoints.down("xl")]: {
    height: "310px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "293px",
  },
  [theme.breakpoints.down("md")]: {
    height: "175px",
  },
  [theme.breakpoints.down(353)]: {
    height: "125px",
  },
  [theme.breakpoints.down(315)]: {
    height: "110px",
  },
}));

export const Allavater = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "451px",
  height: "150px",
  [theme.breakpoints.down("xl")]: {
    width: "365px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "284px",
  },
  [theme.breakpoints.down("md")]: {
    width: "390px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "326px",
  },
  [theme.breakpoints.down(480)]: {
    width: "305px",
  },
  [theme.breakpoints.down(400)]: {
    width: "260px",
  },
  [theme.breakpoints.down(353)]: {
    width: "220px",
  },
  [theme.breakpoints.down(315)]: {
    width: "206px",
  },
  "& img": {
    objectFit: "contain",
  },
  "& p": {
    fontFamily: "PT Sans, sans-serif",
    fontSize: "23px",
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down(2000)]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down(1700)]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "10px",
    },
  },
}));

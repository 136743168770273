import React from "react";

const SectionRenderer = ({ id, title, elements, windowWidth }) => {
  return (
    <div id={id}>
      {windowWidth >= 900 ? <h2>{title}</h2> : null}
      {elements.map((element, idx) => {
        switch (element.type) {
          case "h3":
            return <h3 key={idx}>{element.content}</h3>;
          case "p":
            return (
              <p
                key={idx}
                dangerouslySetInnerHTML={{ __html: element.content }}
              />
            );
          case "ul":
            return (
              <ul key={idx}>
                {element.content.map((li, liIdx) => (
                  <li key={liIdx} dangerouslySetInnerHTML={{ __html: li }} />
                ))}
              </ul>
            );
          case "ol":
            return (
              <ol key={idx}>
                {element.content.map((li, liIdx) => (
                  <li key={liIdx} dangerouslySetInnerHTML={{ __html: li }} />
                ))}
              </ol>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
export default SectionRenderer;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  MainDiv,
  UpperDiv,
  ETLogo,
  Links,
  EmailText,
  Emailsub,
  LowerDiv,
  SocialLink,
  CrText,
  IreText,
  LegalLinks,
  AboutSite,
} from "./FooterStyles";
import { Wrapper } from "../navbar/NavbarStyles";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useAnimateOnView from "../useAnimateOnView";

const logoSrc = "/assets/img/logo.png";
const navbarLinks = [
  { text: "About Us", sectionId: "about" },
  { text: "Services", sectionId: "services" },
  { text: "FAQ's", sectionId: "faq" },
  { text: "Downloads", sectionId: "downloads" },
];
const socialLinks = [
  {
    url: "https://www.facebook.com/",
    iconSrc: "/assets/img/facebook.svg",
    alt: "Facebook",
  },
  {
    url: "https://twitter.com/",
    iconSrc: "/assets/img/twitter.svg",
    alt: "Twitter",
  },
  {
    url: "https://pk.linkedin.com/",
    iconSrc: "/assets/img/linkedin.svg",
    alt: "LinkedIn",
  },
  {
    url: "https://www.whatsapp.com/",
    iconSrc: "/assets/img/whatsapp.svg",
    alt: "WhatsApp",
  },
];
const legalLinksData = [
  { url: "/privacy-policy", text: "Privacy Policy" },
  { url: "/terms-and-conditions", text: "Terms & Conditions" },
  { url: "/eula", text: "EULA" },
];
const irenicTechSrc = "/assets/img/irenictech.svg";
const irenicTechUrl = "https://irenictech.com/";

const Footer = () => {
  const [upperDivRef, isUpperInView] = useAnimateOnView();
  const [lowerDivRef, isLowerInView] = useAnimateOnView();
  const { pathname } = useLocation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const legalLinks = document.querySelectorAll(".legal-links a");
    legalLinks.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      legalLinks.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Wrapper>
      <MainDiv>
        <UpperDiv
          ref={upperDivRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: isUpperInView ? 1 : 0 }}
          transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
        >
          <ETLogo>
            <Link to="/" onClick={() => scrollToSection("nav")}>
              <img src={logoSrc} alt="Logo" />
            </Link>
          </ETLogo>
          {pathname === "/" && (
            <Links>
              <ul className="navbar-links">
                {navbarLinks.map((link, index) => (
                  <li key={index}>
                    <Link onClick={() => scrollToSection(link.sectionId)}>
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Links>
          )}
          <EmailText>
            <Emailsub>
              <input type="email" placeholder="Enter your email" />
              <button type="submit">
                <ArrowForwardIcon />
              </button>
            </Emailsub>
            <p>
              Manage your email subscription easily - unsubscribe by clicking
              here.{" "}
              <Link to="/">
                <span>Unsubscribe</span>
              </Link>
            </p>
          </EmailText>
        </UpperDiv>
        <LowerDiv
          ref={lowerDivRef}
          initial={{ y: "100%", opacity: 0 }}
          animate={isLowerInView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.8 }}
        >
          <SocialLink>
            {socialLinks.map((link, index) => (
              <Link key={index} to={link.url} target="_blank">
                <img src={link.iconSrc} alt={link.alt} />
              </Link>
            ))}
          </SocialLink>
          <hr />
          <AboutSite>
            <CrText>
              <p>© Copyright by EasyTalk - All right reserved.</p>
            </CrText>
            <LegalLinks>
              <ul className="legal-links">
                {legalLinksData.map((item, index) => (
                  <li key={index}>
                    <Link to={item.url}>{item.text}</Link>
                  </li>
                ))}
              </ul>
            </LegalLinks>
            <IreText>
              <p>A product of - </p>
              <Link to={irenicTechUrl} target="_blank">
                <img src={irenicTechSrc} alt="irenictech" />
              </Link>
            </IreText>
          </AboutSite>
        </LowerDiv>
      </MainDiv>
    </Wrapper>
  );
};

export default Footer;

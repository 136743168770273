import { styled } from "@mui/system";
import { motion } from "framer-motion";

export const MainDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#e2f4ff",
  padding: "50px 88px",
  [theme.breakpoints.down("xl")]: {
    padding: "45px 82px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "34px 40px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "23px 15px",
  },
  [theme.breakpoints.down(480)]: {
    padding: "14px 6px",
  },
  [theme.breakpoints.down(353)]: {
    padding: "11px 3px",
  },
}));

export const Heading = styled("div")({
  textAlign: "center",
});

export const HeadingText = styled("h2")(({ theme }) => ({
  marginTop: 0,
  fontFamily: "PT Sans, sans-serif",
  fontWeight: 700,
  fontSize: "36px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "33px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "34px",
  },
  [theme.breakpoints.down(480)]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down(353)]: {
    fontSize: "24px",
  },
}));

export const Cards = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "40px",
  paddingTop: "10px",
  [theme.breakpoints.down("xl")]: {
    gap: "30px",
  },
  [theme.breakpoints.down("lg")]: {
    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
  },
  [theme.breakpoints.down(600)]: {
    gap: "13px",
  },
  [theme.breakpoints.down(480)]: {
    gap: "11px",
  },
  [theme.breakpoints.down(353)]: {
    gap: "10px",
  },
}));

export const Card = styled(motion.div)(({ theme }) => ({
  width: "455px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  display: "flex",
  padding: "20px 23px 10px 23px",
  transition: "background-color 0.3s ease",
  [theme.breakpoints.down("xl")]: {
    padding: "18px 20px 8px 20px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "15px 15px 5px 13px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "18px 20px 12px 17px",
  },
  [theme.breakpoints.down(600)]: {
    width: "380px",
    padding: "10px 8px 7px 10px",
  },
  [theme.breakpoints.down(480)]: {
    width: "338px",
  },
  [theme.breakpoints.down(400)]: {
    width: "294px",
  },
  [theme.breakpoints.down(358)]: {
    width: "261px",
  },
  [theme.breakpoints.down(315)]: {
    width: "228px",
  },
  "&:hover": {
    backgroundColor: "#229ee6",
    cursor: "pointer",
    "& p": {
      color: "#fff",
    },
  },
}));

export const CardText = styled("div")(({ theme }) => ({
  padding: "0 5px 5px 15px",
  [theme.breakpoints.down("xl")]: {
    padding: "0 2px 0 7px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "4px 0 0 9px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "3px 10px 3px 11px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "3px 8px 2px 7px",
  },
  [theme.breakpoints.down(480)]: {
    padding: "2px 8px 2px 7px",
  },
  [theme.breakpoints.down(353)]: {
    padding: "5px 3px 2px 6px",
  },
}));

export const Logo = styled("div")({});

export const LogoImage = styled("img")(({ theme }) => ({
  objectFit: "contain",
  [theme.breakpoints.down(480)]: {
    width: "32px",
    height: "30px",
  },
  [theme.breakpoints.down(400)]: {
    width: "29.21px",
    height: "34.5px",
  },
  [theme.breakpoints.down(353)]: {
    width: "25.21px",
    height: "34.5px",
  },
}));

export const CardHead = styled("h3")(({ theme }) => ({
  margin: 0,
  fontFamily: "PT Sans, sans-serif",
  fontWeight: 700,
  fontSize: "29px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "27px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "21px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "27px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "23px",
  },
  [theme.breakpoints.down(480)]: {
    fontSize: "21px",
  },
  [theme.breakpoints.down(353)]: {
    fontSize: "18px",
  },
}));

export const CardParagraph = styled("p")(({ theme }) => ({
  fontFamily: "PT Sans, sans-serif",
  fontWeight: 400,
  fontSize: "15px",
  marginTop: "8.5px",
  transition: "color 0.3s ease",
  [theme.breakpoints.down("xl")]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down(480)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(353)]: {
    fontSize: "11px",
  },
}));

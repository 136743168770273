import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  FaqWrapper,
  MainDiv,
  FaqContainer,
  Row,
  FaqContent,
  FaqHeader,
  FaqImg,
  MdImg,
} from "./FaqStyles";
import QuestionItem from "./QuestionItem";
import { faqData } from "./Data";
import useAnimateOnView from "../useAnimateOnView";

const faqImg = "/assets/img/FAQ.svg";

const Faq = () => {
  const [imgRef, isImgInView] = useAnimateOnView();
  const theme = useTheme();
  const isBelowXL = useMediaQuery(theme.breakpoints.down("xl"));
  const isBelowLG = useMediaQuery(theme.breakpoints.down("lg"));
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <FaqWrapper id="faq">
      <MainDiv>
        <FaqContainer>
          <FaqHeader>
            <h1>Frequently asked questions</h1>
          </FaqHeader>
          {isBelowMd && (
            <MdImg
              ref={imgRef}
              initial={{ opacity: 0, x: "100%" }}
              animate={{
                opacity: isImgInView ? 1 : 0,
                x: isImgInView ? 0 : "100%",
              }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <img src={faqImg} alt="FAQ" />
            </MdImg>
          )}
          <FaqContent>
            <Row container spacing={isBelowLG ? 2 : isBelowXL ? 5 : 6}>
              <Grid item xs={isBelowMd ? 12 : 6}>
                <QuestionItem
                  id={faqData[0].id}
                  isfixedheight={true}
                  question={faqData[0].question}
                  answer={faqData[0].answer}
                />
              </Grid>
              {!isBelowMd && (
                <Grid item xs={6}>
                  <FaqImg
                    ref={imgRef}
                    initial={{ opacity: 0, x: "100%" }}
                    animate={{
                      opacity: isImgInView ? 1 : 0,
                      x: isImgInView ? 0 : "100%",
                    }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                  >
                    <img src={faqImg} alt="FAQ" />
                  </FaqImg>
                </Grid>
              )}
            </Row>
            <Row container spacing={isBelowLG ? 2 : isBelowXL ? 5 : 6}>
              {faqData.slice(1, 3).map((faq, index) => (
                <Grid item xs={isBelowMd ? 12 : 6} key={index}>
                  <QuestionItem
                    question={faq.question}
                    answer={faq.answer}
                    id={faq.id}
                  />
                </Grid>
              ))}
            </Row>
            <Row container spacing={isBelowLG ? 2 : isBelowXL ? 5 : 6}>
              {faqData.slice(3, 5).map((faq, index) => (
                <Grid item xs={isBelowMd ? 12 : 6} key={index}>
                  <QuestionItem
                    question={faq.question}
                    answer={faq.answer}
                    id={faq.id}
                  />
                </Grid>
              ))}
            </Row>
          </FaqContent>
        </FaqContainer>
      </MainDiv>
    </FaqWrapper>
  );
};

export default Faq;

import React from "react";
import ChatCard from "./ChatCard";
import DetailComponent from "./DetailComponent";
import { chatData, detailData } from "./Data";
import {
  ServicesWrapper,
  MainDiv,
  LeftDiv,
  LeftFirst,
  ChatAbout,
  ChatSection,
  About,
  LeftSecond,
  RightDiv,
  RightFirst,
  RightSecond,
  Allavater,
  EasytalkImg,
  EasytalkHeading,
} from "./ServicesStyles";
import useAnimateOnView from "../useAnimateOnView";

const MobilePreviewImgSrc = "/assets/img/Mobile-preview.png";
const AllAvatarsImgSrc = "/assets/img/Allavatars.png";

const Services = () => {
  const [leftDivRef, isLeftDivInView] = useAnimateOnView();
  const [leftSecondDivRef, isLeftSecondDivInView] = useAnimateOnView();
  const [rightDivRef, isRightDivInView] = useAnimateOnView();
  const [rightSecondDivRef, isRightSecondDivInView] = useAnimateOnView();

  return (
    <ServicesWrapper id="services">
      <MainDiv>
        <LeftDiv>
          <LeftFirst
            ref={leftDivRef}
            initial={{ opacity: 0, x: "-100%" }}
            animate={{
              opacity: isLeftDivInView ? 1 : 0,
              x: isLeftDivInView ? 0 : "-100%",
            }}
            transition={{ duration: 0.75, ease: "easeOut" }}
          >
            <ChatAbout>
              <ChatSection>
                {chatData.map((chat) => (
                  <ChatCard
                    key={chat.id}
                    avatarSrc={chat.avatarSrc}
                    name={chat.name}
                    message={chat.message}
                    time={chat.time}
                    badgeCount={chat.badgeCount}
                  />
                ))}
              </ChatSection>
              <About>
                <p>
                  Talk with friends, family, colleagues, and anyone else
                  effortlessly with our chat app. Seamlessly connect with the
                  people who matter most, anytime, anywhere.
                </p>
              </About>
            </ChatAbout>
          </LeftFirst>
          <LeftSecond
            ref={leftSecondDivRef}
            initial={{ opacity: 0, x: "-100%" }}
            animate={{
              opacity: isLeftSecondDivInView ? 1 : 0,
              x: isLeftSecondDivInView ? 0 : "-100%",
            }}
            transition={{ duration: 0.75, ease: "easeOut" }}
          >
            {detailData.map((detail) => (
              <DetailComponent
                key={detail.id}
                imgSrc={detail.imgSrc}
                altText={detail.altText}
                heading={detail.heading}
                description={detail.description}
              />
            ))}
          </LeftSecond>
        </LeftDiv>
        <RightDiv>
          <RightFirst
            ref={rightDivRef}
            initial={{ opacity: 0, x: "100%" }}
            animate={{
              opacity: isRightDivInView ? 1 : 0,
              x: isRightDivInView ? 0 : "100%",
            }}
            transition={{ duration: 0.75, ease: "easeOut" }}
          >
            <EasytalkHeading>
              <h2>
                Welcome to <span>EasyTalk</span>
              </h2>
            </EasytalkHeading>
            <EasytalkImg>
              <img
                src={MobilePreviewImgSrc}
                alt="EasyTalk Mobile Preview"
              />
            </EasytalkImg>
          </RightFirst>
          <RightSecond
            ref={rightSecondDivRef}
            initial={{ opacity: 0, x: "100%" }}
            animate={{
              opacity: isRightSecondDivInView ? 1 : 0,
              x: isRightSecondDivInView ? 0 : "100%",
            }}
            transition={{ duration: 0.75, ease: "easeOut" }}
          >
            <Allavater>
              <img src={AllAvatarsImgSrc} alt="allavatar" />
              <p>
                EasyTalk is your go-to platform for connecting with others
                seamlessly and effortlessly. Whether you're chatting with
                friends, family, colleagues, or making new connections.
              </p>
            </Allavater>
          </RightSecond>
        </RightDiv>
      </MainDiv>
    </ServicesWrapper>
  );
};

export default Services;

import React from "react";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  MainDiv,
  Container1,
  Container2,
  Heading,
  ContentP,
  DButton,
  Img1,
  Img2,
} from "./HeaderStyles";
import { Wrapper } from "../navbar/NavbarStyles";

const group1Img = "/assets/img/Group1.png";
const group2Img = "/assets/img/Group2.svg";

const Header = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Wrapper>
      <MainDiv>
        <Container1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3, ease: "easeOut" }}
        >
          <Heading>
            <h2>Connect Seamlessly, Anytime, Anywhere.</h2>
            <ContentP>
              In the universe of your chat, EasyTalk empowers you to define the
              rules. Your conversations, your way, connecting you to your world
              like never before.
            </ContentP>
          </Heading>
          <DButton>
            <Button
              onClick={() => scrollToSection("downloads")}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
            >
              Start Chatting Now
            </Button>
          </DButton>
        </Container1>
        <Container2>
          <Img1
            initial={{ opacity: 0, scale: 0.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <img src={group1Img} alt="group 1" />
          </Img1>
          <Img2
            initial={{ opacity: 0, x: "100%", y: "-50%" }}
            animate={{ opacity: 1, x: "-50%", y: "-50%" }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <img src={group2Img} alt="group 2" />
          </Img2>
        </Container2>
      </MainDiv>
    </Wrapper>
  );
};

export default Header;

import { styled } from "@mui/system";

export const RightDiv = styled("div")(({ theme }) => ({
  scrollBehavior: "smooth",
  overflow: "auto",
  paddingLeft: "50px",
  height: "650px",
  scrollbarWidth: "none",
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "20px",
  },
  "& h2": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "35px",
    margin: 0,
    [theme.breakpoints.down(2000)]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
  },
  "& h3": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "27px",
    margin: "10px 0",
    [theme.breakpoints.down(2000)]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
  },
  "& p, & a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "19px",
    marginTop: "10px",
    color: "black",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
  },
  "& li": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "19px",
    marginTop: 0,
    color: "black",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
  },
}));

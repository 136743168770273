import React from "react";
import {
  Chat,
  TimeBadge,
  CustomBadge,
  Time,
  NameMessage,
  Name,
  Message,
  ResponsiveAvatar
} from "./ServicesStyles";

const ChatCard = ({ avatarSrc, name, message, time, badgeCount }) => {
  return (
    <Chat>
       <div>
        <ResponsiveAvatar src={avatarSrc} />
      </div>
      <NameMessage>
        <Name badgeCount={badgeCount}>{name}</Name>
        <Message>{message}</Message>
      </NameMessage>
      <TimeBadge>
        <Time badgeCount={badgeCount}>{time}</Time>
        {badgeCount && (
          <CustomBadge>
            <span>{badgeCount}</span>
          </CustomBadge>
        )}
      </TimeBadge>
    </Chat>
  );
};

export default ChatCard;
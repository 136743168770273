import React from "react";
import { cardData } from "./CardData";
import {
  MainDiv,
  Heading,
  HeadingText,
  Cards,
  Card,
  Logo,
  LogoImage,
  CardText,
  CardHead,
  CardParagraph,
} from "./FeaturesStyles";
import { Wrapper } from "../navbar/NavbarStyles"
import useAnimateOnView from "../useAnimateOnView";

const Features = () => {
  const [card1Ref, isCard1InView] = useAnimateOnView();
  const [card2Ref, isCard2InView] = useAnimateOnView();
  const [card3Ref, isCard3InView] = useAnimateOnView();
  return (
    <Wrapper id="about">
      <MainDiv>
        <Heading>
          <HeadingText>Get the best features</HeadingText>
        </Heading>
        <Cards>
          <Card
            ref={card1Ref}
            initial={{ opacity: 0, x: "-100%" }}
            animate={{
              opacity: isCard1InView ? 1 : 0,
              x: isCard1InView ? 0 : "-100%",
            }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <Logo>
              <LogoImage src={cardData[0].logoSrc} alt={cardData[0].altText} />
            </Logo>
            <CardText>
              <CardHead>{cardData[0].heading}</CardHead>
              <CardParagraph>{cardData[0].paragraph}</CardParagraph>
            </CardText>
          </Card>
          <Card
            ref={card2Ref}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: isCard2InView ? 1 : 0,
              scale: isCard2InView ? 1 : 0,
            }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <Logo>
              <LogoImage src={cardData[1].logoSrc} alt={cardData[1].altText} />
            </Logo>
            <CardText>
              <CardHead>{cardData[1].heading}</CardHead>
              <CardParagraph>{cardData[1].paragraph}</CardParagraph>
            </CardText>
          </Card>

          <Card
            ref={card3Ref}
            initial={{ opacity: 0, x: "100%" }}
            animate={{
              opacity: isCard3InView ? 1 : 0,
              x: isCard3InView ? 0 : "100%",
            }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <Logo>
              <LogoImage src={cardData[2].logoSrc} alt={cardData[2].altText} />
            </Logo>
            <CardText>
              <CardHead>{cardData[2].heading}</CardHead>
              <CardParagraph>{cardData[2].paragraph}</CardParagraph>
            </CardText>
          </Card>
        </Cards>
      </MainDiv>
    </Wrapper>
  );
};

export default Features;

import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const MainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "50px 132px 50px 200px",
  [theme.breakpoints.down(2000)]: {
    padding: "50px 132px 50px 160px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "50px 65px 50px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "35px 65px",
  },
  [theme.breakpoints.down(1050)]: {
    padding: "35px 45px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: "20px 0",
  },
}));

export const Info = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "75px",
  [theme.breakpoints.down(2000)]: {
    gap: "35px",
  },
  [theme.breakpoints.down("md")]: {
    gap: "15px",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const HeadText = styled("div")(({ theme }) => ({
  width: "1000px",
  [theme.breakpoints.down(2000)]: {
    width: "800px",
  },
  [theme.breakpoints.down("xl")]: {
    width: "650px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "600px",
  },
  [theme.breakpoints.down(1050)]: {
    width: "510px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down(600)]: {
    width: "400px",
  },
  [theme.breakpoints.down(480)]: {
    width: "320px",
  },
  [theme.breakpoints.down(400)]: {
    width: "270px",
  },
  [theme.breakpoints.down(315)]: {
    width: "240px",
  },
  "& h1": {
    margin: 0,
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "50px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down(1050)]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
      textAlign: "center",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "23px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "18px",
    },
  },
  "& p": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "27px",
    margin: "15px 0",
    [theme.breakpoints.down(2000)]: {
      fontSize: "22px",
      marginRight: "50px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "18px",
      margin: "5px 33px 0 0",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down(1050)]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      textAlign: "center",
      margin: "5px 30px 0 30px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "15px",
      margin: "5px 20px 0 20px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "14px",
      margin: "5px 10px 0 10px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "13px",
      margin: "5px 5px 0 5px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "12px",
    },
  },
  "& span": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "27px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down(1050)]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "12px",
    },
  },
}));

export const GetOn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  [theme.breakpoints.down(2000)]: {
    gap: "13px",
  },
  [theme.breakpoints.down("xl")]: {
    gap: "8px",
  },
  [theme.breakpoints.down("md")]: {
    gap: "12px",
    marginTop: "15px",
  },
  [theme.breakpoints.down(600)]: {
    gap: "10px",
    marginTop: "13px",
  },
  [theme.breakpoints.down(480)]: {
    gap: "8px",
    marginTop: "10px",
  },
  [theme.breakpoints.down(315)]: {
    gap: "4px",
    marginTop: "5px",
  },
  "& span": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "27px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down(1050)]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      textAlign: "center",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "12px",
    },
  },
}));

export const Storeimg = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  [theme.breakpoints.down(600)]: {
    gap: "8px",
  },
}));

export const StoreLink = styled(Link)(({ theme }) => ({
  display: "flex",
    width: "28%",
    [theme.breakpoints.down(2000)]: {
      width: "25%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "23%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
    [theme.breakpoints.down(480)]: {
      width: "80%",
    },
    [theme.breakpoints.down(400)]: {
      width: "70%",
    },
    [theme.breakpoints.down(315)]: {
      width: "65%",
    },
    "& img": {
      objectFit: "contain",
      width: "100%",

    },
}));

export const EasyImg = styled(motion.div)(({ theme }) => ({
  width: "600px",
  [theme.breakpoints.down(2000)]: {
    width: "460px",
  },
  [theme.breakpoints.down("xl")]: {
    width: "350px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
  [theme.breakpoints.down(1050)]: {
    width: "270px",
  },
  [theme.breakpoints.down("md")]: {
    width: "380px",
    marginTop: "55px",
  },
  [theme.breakpoints.down(600)]: {
    width: "350px",
    marginTop: "45px",
  },
  [theme.breakpoints.down(480)]: {
    width: "300px",
    marginTop: "40px",
  },
  [theme.breakpoints.down(400)]: {
    width: "256px",
    marginTop: "25px",
  },
  [theme.breakpoints.down(315)]: {
    width: "220px",
    marginTop: "16px",
  },
  "& img": {
    objectFit: "contain",
    width: "100%",
  },
}));
import React from "react";
import {
  Detail1,
  DetailImg,
  DetailHeading,
  DetailText,
} from "./ServicesStyles";

const DetailComponent = ({ imgSrc, altText, heading, description }) => {
  return (
    <Detail1>
      <DetailImg>
        <img src={imgSrc} alt={altText} />
      </DetailImg>
      <DetailHeading>
        <h2>{heading}</h2>
      </DetailHeading>
      <DetailText>
        <p>{description}</p>
      </DetailText>
    </Detail1>
  );
};

export default DetailComponent;
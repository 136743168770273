import React from "react";
import {
  MainDiv,
  Info,
  HeadText,
  GetOn,
  Storeimg,
  EasyImg,
  StoreLink,
} from "./DownloadsStyles";
import { Wrapper } from "../navbar/NavbarStyles";
import useAnimateOnView from "../useAnimateOnView";

const googlePlayURL =
  "https://play.google.com/store/apps/details?id=com.irenictech.easytalk&pcampaignid=web_share";
const appStoreURL =
  "https://apps.apple.com/pk/app/easytalk-calls-and-text/id6502940016";
const googlePlayImg = "/assets/img/GooglePlay.svg";
const appStoreImg = "/assets/img/AppStore.svg";
const phoneImg = "/assets/img/DPhone.svg";

const Downloads = () => {
  const [infoRef, isInfoInView] = useAnimateOnView();
  const [imgRef, isImgInView] = useAnimateOnView();
  return (
    <Wrapper id="downloads">
      <MainDiv>
        <Info
          ref={infoRef}
          initial={{ opacity: 0, x: "-100%" }}
          animate={{
            opacity: isInfoInView ? 1 : 0,
            x: isInfoInView ? 0 : "-100%",
          }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <HeadText>
            <h1>Download Now for Seamless Communication</h1>
            <p>
              Experience the future of communication. Download{" "}
              <span>EasyTalk</span> now and connect, chat, and share
              effortlessly with friends, family, and colleagues. Start
              conversations that matter today.
            </p>
          </HeadText>
          <GetOn>
            <span>Get the App now</span>
            <Storeimg>
              <StoreLink to={googlePlayURL} target="_blank">
                <img src={googlePlayImg} alt="EasyTalk-Iphone13pro" />
              </StoreLink>
              <StoreLink to={appStoreURL} target="_blank">
                <img src={appStoreImg} alt="EasyTalk-Iphone13pro" />
              </StoreLink>
            </Storeimg>
          </GetOn>
        </Info>
        <EasyImg
          ref={imgRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: isImgInView ? 1 : 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <img src={phoneImg} alt="EasyTalk-Iphone13pro" />
        </EasyImg>
      </MainDiv>
    </Wrapper>
  );
};

export default Downloads;

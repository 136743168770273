const faqData = [
  {
    id: 0,
    question:
      "How can I use domain-based networking to connect with colleagues from my company on EasyTalk?",
    answer:
      "Domain-based networking on EasyTalk allows you to directly connect with colleagues and professionals within your organization by joining your company's domain. Additionally, you can send friend requests to connect with individuals outside your domain, ensuring seamless communication both within and beyond your professional network.",
  },
  {
    id: 1,
    question:
      "What measures are in place to ensure privacy and security in EasyTalk?",
    answer:
      "We take privacy and security seriously. EasyTalk employs advanced encryption and security measures to safeguard your conversations and personal information. Your data is secure, giving you peace of mind.",
  },
  {
    id: 2,
    question:
      "What happens when I send a friend request to someone outside my domain?",
    answer:
      "When you send a friend request to someone outside of your domain, they'll receive a notification. If they accept, you can start connecting with them as you would with in-domain connections.",
  },
  {
    id: 3,
    question:
      "How does the real-time translation feature enhance global connections?",
    answer:
      "Real-time translation breaks language barriers, making it easy to chat with friends, colleagues, or connections from around the world. You can communicate effortlessly and build meaningful global relationships.",
  },
  {
    id: 4,
    question:
      "Tell me more about the audio and video call quality. Is it reliable?",
    answer:
      "We've invested in high-quality audio and video call technology to ensure a reliable and clear connection. You can count on EasyTalk for crystal-clear conversations, whether it's a quick chat or a work meeting.",
  },
];

export { faqData };

const cardData = [
  {
    logoSrc: "/assets/img/Frame3.png",
    altText: "group 1",
    heading: "Video Messaging",
    paragraph:
      "Elevate your conversations with EasyTalk's video messaging feature. Connect face-to-face, no matter the distance, and share moments in real-time.",
  },
  {
    logoSrc: "/assets/img/Frame1.png",
    altText: "group 1",
    heading: "Save Your Time",
    paragraph:
      "Experience hassle-free communication with EasyTalk's time-saving feature, ensuring you spend less time typing and more time connecting with loved ones.",
  },
  {
    logoSrc: "/assets/img/Frame2.png",
    altText: "group 1",
    heading: "Keep Safe & Private",
    paragraph:
      "Rest easy knowing that your conversations are shielded from prying eyes, guaranteeing a secure and confidential chatting experience with EasyTalk.",
  },
];
export { cardData };

import { useRef, useEffect, useState } from "react";

const useAnimateOnView = () => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(true);
  const [lastYPos, setLastYPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.scrollY;
      if (ref.current) {
        if (yPos > lastYPos) {
          const isIntersecting =
            ref.current.getBoundingClientRect().top <= window.innerHeight;
          setIsInView(isIntersecting);
        }
        setLastYPos(yPos);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastYPos]);

  return [ref, isInView];
};

export default useAnimateOnView;

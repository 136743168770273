import { styled } from "@mui/system";
import { motion } from "framer-motion";

export const MainDiv = styled("div")(({ theme }) => ({
  padding: "50px 100px 20px 100px",
  backgroundColor: "#e2f4ff",
  [theme.breakpoints.down("lg")]: {
    padding: "30px 36px 20px 36px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "36px 36px 20px 36px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "20px 0",
  },
}));

export const UpperDiv = styled(motion.div)(({ theme }) => ({
  display: "flex",
  margin: "10px",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    margin: "8px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const ETLogo = styled("div")(({ theme }) => ({
  "& img": {
    objectFit: "contain",
    width: "150px",
    [theme.breakpoints.down(2000)]: {
      width: "140px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "130px",
    },
    [theme.breakpoints.down("md")]: {
      width: "160px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
    [theme.breakpoints.down(400)]: {
      width: "130px",
    },
  },
}));

export const Links = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: "8px",
  },
  [theme.breakpoints.down(400)]: {
    marginTop: "4px",
  },
  ".navbar-links": {
    listStyleType: "none",
    display: "flex",
    paddingLeft: 0,
  },
  ".navbar-links li": {
    marginRight: "74px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "32px",
    },
    [theme.breakpoints.down(400)]: {
      marginRight: "17px",
    },
  },
  ".navbar-links li:last-child": {
    marginRight: 0,
  },
  ".navbar-links li a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#212324",
    textDecoration: "none",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "13px",
    },
  },
}));

export const EmailText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down(400)]: {
    marginTop: "4px",
  },
  "& p": {
    fontFamily: '"Lexend", sans-serif',
    fontWeight: 400,
    fontSize: "12px",
    margin: 0,
    marginTop: "5px",
    width: "265px",
    textAlign: "center",
    [theme.breakpoints.down(2000)]: {
      width: "232px",
      fontSize: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      width: "245px",
      paddingLeft: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "227px",
      paddingLeft: "5px",
    },
    [theme.breakpoints.down(400)]: {
      width: "192px",
      paddingLeft: "8px",
      fontSize: "9px",
    },
  },
  "& a": {
    textDecoration: "none",
    fontFamily: '"Lexend", sans-serif',
    fontWeight: 700,
    fontSize: "12px",
    margin: 0,
    color: "#212324",
    [theme.breakpoints.down(2000)]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "9px",
    },
  },
}));

export const Emailsub = styled("div")(({ theme }) => ({
  position: "relative",
  "& input": {
    width: "217px",
    height: "40px",
    borderRadius: "1000px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "40px",
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    color: "#212324",
    [theme.breakpoints.down(2000)]: {
      fontSize: "14px",
      width: "190px",
      height: "36px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "148px",
      height: "34px",
    },
    [theme.breakpoints.down("md")]: {
      width: "244px",
      height: "42px",
      paddingLeft: "27px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "190px",
      height: "40px",
      paddingLeft: "13px",
    },
    [theme.breakpoints.down(400)]: {
      width: "158px",
      height: "37px",
      paddingLeft: "10px",
      paddingRight: "36px",
    },
    "&::placeholder": {
      fontFamily: '"PT Sans", sans-serif',
      fontWeight: 400,
      fontSize: "16px",
      color: "#212324",
      [theme.breakpoints.down(2000)]: {
        fontSize: "14px",
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  "& button": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "5px",
    top: "3px",
    width: "34px",
    height: "34px",
    borderRadius: "1000px",
    border: "none",
    backgroundColor: "#229ee6",
    color: "#ffffff",
    cursor: "pointer",
    [theme.breakpoints.down(2000)]: {
      width: "32px",
      height: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "31px",
      height: "31px",
    },
    [theme.breakpoints.down("md")]: {
      width: "33px",
      height: "33px",
      top: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "31px",
      height: "31px",
      top: "5px",
    },
    [theme.breakpoints.down(400)]: {
      width: "28px",
      height: "28px",
      top: "6px",
    },
  },
}));

export const LowerDiv = styled(motion.div)(({ theme }) => ({
  marginTop: "40px",
  [theme.breakpoints.down(400)]: {
    marginTop: "30px",
    margin: "30px 10px 0 10px",
  },
  "& hr": {
    border: "1px solid #212324",
    margin: "15px 0",
  },
}));

export const SocialLink = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "26px",
  [theme.breakpoints.down(2000)]: {
    gap: "23px",
  },
  [theme.breakpoints.down("lg")]: {
    gap: "22px",
  },
  img: {
    width: "38px",
    height: "38px",
    [theme.breakpoints.down(2000)]: {
      width: "34px",
      height: "34px",
    },
    [theme.breakpoints.down("md")]: {
      width: "39px",
      height: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "32px",
      height: "32px",
    },
    [theme.breakpoints.down(400)]: {
      width: "29px",
      height: "29px",
    },
  },
}));

export const CrText = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
  },
  "& p": {
    fontFamily: '"Poppins", sans-serif',
    fontSize: "13px",
    fontWeight: 500,
    [theme.breakpoints.down(2000)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "12px",
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "10px",
    },
  },
}));

export const AboutSite = styled(motion.div)(({ theme }) => ({
  display: "flex",
  margin: "10px",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    margin: "8px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const LegalLinks = styled("div")(({ theme }) => ({
  ".legal-links": {
    listStyleType: "none",
    display: "flex",
    paddingLeft: 0,
  },
  ".legal-links li": {
    marginRight: "23px",
  },
  ".legal-links li:last-child": {
    marginRight: 0,
  },
  ".legal-links li a": {
    fontFamily: '"Poppins", sans-serif',
    fontSize: "13px",
    fontWeight: 500,
    color: "#212324",
    textDecoration: "none",
    [theme.breakpoints.down(2000)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "10px",
    },
  },
}));

export const IreText = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
  "& p": {
    fontFamily: '"Poppins", sans-serif',
    fontSize: "13px",
    fontWeight: 500,
    [theme.breakpoints.down(2000)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "10px",
    },
  },
  "& img": {
    objectFit: "contain",
    width: "90px",
    [theme.breakpoints.down(2000)]: {
      width: "80px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "76px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "65px",
    },
    [theme.breakpoints.down("md")]: {
      width: "70px",
      marginTop: "9px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "65px",
      marginTop: "8px",
    },
    [theme.breakpoints.down(400)]: {
      marginTop: "7px",
      width: "64px",
    },
  },
}));

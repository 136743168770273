import { styled } from "@mui/system";
import { motion } from "framer-motion";

export const MainDiv = styled("div")(({ theme }) => ({
  padding: "0 100px",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    padding: "0 36px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: "0 20px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "0 14px",
  },
}));

export const Container1 = styled(motion.div)(({ theme }) => ({
  gridColumn: 1,
  width: "600px",
  height: "427px",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(2000)]: {
    height: "400px",  
  },
  [theme.breakpoints.down("xl")]: {
    height: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "320px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    alignItems: "center",
    height: "auto",
  },
}));

export const Heading = styled("div")(({ theme }) => ({
  "& h2": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "70px",
    textAlign: "left",
    margin: 0,
    [theme.breakpoints.down(2000)]: {
      fontSize: "61px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "59px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "49px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      textAlign: "center",
      padding: "0 3px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "36px",
      padding: "0 1px",
    },
  },
}));

export const ContentP = styled("p")(({ theme }) => ({
  fontFamily: '"PT Sans", sans-serif',
  fontSize: "22px",
  fontWeight: 400,
  textAlign: "left",
  paddingTop: "10px",
  [theme.breakpoints.down(2000)]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "19px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "17px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    textAlign: "center",
    padding: "0 30px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "0 1px",
    fontSize: "14px",
  },
}));

export const DButton = styled("p")(({ theme }) => ({
  marginTop: "auto",
  "& button": {
    width: "236px",
    height: "48px",
    padding: "8px 20px 6px",
    borderRadius: "99px",
    backgroundColor: "#229ee6",
    fontFamily: '"PT Sans", sans-serif',
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down(2000)]: {
    "& button": {
      width: "215px",
      height: "46px",
      fontSize: "15px",
      padding: "8px 18px 6px",
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "30px",
  },
  [theme.breakpoints.down(400)]: {
    marginTop: "13px",
    "& button": {
      width: "195px",
      height: "38px",
      fontSize: "12px",
      padding: "6px 15px 4px",
    },
  },
}));
export const Container2 = styled("div")(({ theme }) => ({
  gridColumn: 2,
  width: "690px",
  height: "821px",
  position: "relative",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(2000)]: {
    width: "550px",
    height: "588px",
  },
  [theme.breakpoints.down("xl")]: {
    height: "519px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "461px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "380px",
    alignItems: "center",
    padding: "0 20px",
  },
  [theme.breakpoints.down(480)]: {
    height: "310px",
  },
  [theme.breakpoints.down(400)]: {
    height: "295px",
  },
  [theme.breakpoints.down(345)]: {
    height: "280px",
  },
  [theme.breakpoints.down(300)]: {
    height: "265px",
  },
}));

export const Img1 = styled(motion.div)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  [theme.breakpoints.between(900, 1200)]: {
    left: "13%",
  },
  [theme.breakpoints.down("md")]: {
    "& img": {
      position: "static",
    },
  },
  [theme.breakpoints.down(480)]: {
    left: "5%",
  },
}));

export const Img2 = styled(motion.div)(({ theme }) => ({
  position: "absolute",
  objectFit: "contain",
  top: "70%",
  left: "40%",
  transform: "translate(-50%, -50%)",
  "& img": {
    height: "285px",
  },
  [theme.breakpoints.down(2000)]: {
    left: "37%",
    "& img": {
      height: "212px",
    },
  },
  [theme.breakpoints.down("xl")]: {
    left: "40%",
    "& img": {
      height: "204px",
    },
  },
  [theme.breakpoints.down(1200)]: {
    left: "55%",
    "& img": {
      height: "170.5px",
    },
  },
  [theme.breakpoints.down("md")]: {
    left: "50%",
  },
  [theme.breakpoints.down(480)]: {
   "& img": {
      height: "142.5px",
    },
  },
  [theme.breakpoints.down(400)]: {
    "& img": {
      height: "120.5px",
    },
  },
  [theme.breakpoints.down(345)]: {
    "& img": {
      height: "108.5px",
    },
  },
  [theme.breakpoints.down(300)]: {
    "& img": {
      height: "99.5px",
    },
  },
}));
import { styled } from "@mui/system";
import { Button, Drawer, IconButton, List, ListItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { motion } from "framer-motion";

export const Wrapper = styled("div")({
  maxWidth: "2400px",
  margin: "0 auto",
  overflow: "hidden",
});

export const NavbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "150px",
  padding: "0 100px",
  justifyContent: "space-between",
  [theme.breakpoints.between(900, 1200)]: {
    padding: "36px",
    height: "100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 40px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "0 20px",
  },
}));

export const Logo = styled(motion.div)(({ theme }) => ({
  img: {
    objectFit: "contain",
    width: "140px",
    [theme.breakpoints.down("md")]: {
      width: "120px",
    },
    [theme.breakpoints.down(900)]: {
      content: `url("/assets/img/logo2.png")`,
      width: "65px",
      height: "65px",
    },
    [theme.breakpoints.down(400)]: {
      width: "57px",
      height: "57px",
    },
  },
}));

export const Links = styled(motion.div)(({ theme }) => ({
  ".navbar-links": {
    listStyleType: "none",
    display: "flex",
    paddingLeft: 0,
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down(900)]: {
      display: "none",
    },
  },
  ".navbar-links li": {
    marginRight: "74px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "20px",
    },
  },
  ".navbar-links li:last-child": {
    marginRight: 0,
  },
  ".navbar-links li a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#212324",
    textDecoration: "none",
  },
}));

export const DownloadButton = styled(motion(Button))(({ theme }) => ({
  width: "180px",
  height: "42px",
  borderRadius: "99px",
  backgroundColor: "#229EE6",
  "&:hover": { backgroundColor: "#007BBB" },
  [theme.breakpoints.down("md")]: {
    width: "100px",
  },
  [theme.breakpoints.down(900)]: {
    display: "none",
  },
}));

export const HamburgerButton = styled(motion(IconButton))(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down(900)]: {
    display: "block",
    width: "50px",
    height: "50px",
  },
  [theme.breakpoints.down(400)]: {
    display: "block",
    width: "40px",
    height: "40px",
  },
}));

export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  fontSize: "35px",
  [theme.breakpoints.down(400)]: {
    display: "block",
    width: "32px",
    height: "32px",
  },
}));

export const StyledDrawer = styled(Drawer)(() => ({
  "& .MuiDrawer-paper": {
    width: "321px",
    borderRadius: "50px 0 0 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${"/assets/img/drawer-back.png"})`,
    backgroundSize: "cover",
  },
}));

export const StyledLogo = styled(Logo)(() => ({
  position: "relative",
  top: "25px",
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  height: "750px",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center",
  "& .MuiListItem-root": {
    width: "100%",
    textAlign: "center",
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& hr": {
    width: "20px",
    border: 0,
    borderBottom: "1px solid #212324",
    marginBottom: theme.spacing(4),
  },
  "& .MuiTypography-body1": {
    fontFamily: '"PT Sans", sans-serif',
    fontSize: "18px",
    fontWeight: 400,
  },
}));

export const StyledCloseButton = styled(ListItem)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(4),
  width: "100%",
  textAlign: "center",
  fontFamily: '"PT Sans", sans-serif',
  fontSize: "18px",
  fontWeight: 400,
}));

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Wrapper,
  NavbarContainer,
  Logo,
  Links,
  DownloadButton,
  HamburgerButton,
  StyledMenuIcon,
  StyledDrawer,
  StyledLogo,
  StyledList,
  StyledCloseButton,
} from "./NavbarStyles";

import { ListItem, ListItemText } from "@mui/material";

const logoSrc = "/assets/img/logo.png";
const logo2Src = "/assets/img/logo2.png";
const navbarLinks = [
  { text: "About Us", sectionId: "about" },
  { text: "Services", sectionId: "services" },
  { text: "FAQ's", sectionId: "faq" },
  { text: "Downloads", sectionId: "downloads" },
];

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper id="nav">
      <NavbarContainer>
        <Logo
          initial={{ opacity: 0, x: "-100%" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
        >
          <Link to="/">
            <img src={logoSrc} alt="Logo" />
          </Link>
        </Logo>
        {pathname === "/" && (
          <>
            <Links
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.75, ease: "easeOut" }}
            >
              <ul className="navbar-links">
                {navbarLinks.map((link, index) => (
                  <li key={index}>
                    <Link onClick={() => scrollToSection(link.sectionId)}>
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Links>
            <Link to="#" onClick={() => scrollToSection("downloads")}>
              <DownloadButton
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.75 }}
                variant="contained"
              >
                Download
              </DownloadButton>
            </Link>
            <HamburgerButton
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.75 }}
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <StyledMenuIcon />
            </HamburgerButton>
            <StyledDrawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              <StyledLogo>
                <img src={logo2Src} alt="Logo" />
              </StyledLogo>
              <StyledList>
                {navbarLinks.map((link, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      component={Link}
                      onClick={() => {
                        scrollToSection(link.sectionId);
                        handleDrawerToggle();
                      }}
                    >
                      <ListItemText primary={link.text} />
                    </ListItem>
                    {index < navbarLinks.length - 1 && <hr />}
                  </React.Fragment>
                ))}
              </StyledList>
              <StyledCloseButton button onClick={handleDrawerToggle}>
                <ListItemText primary="Close" />
              </StyledCloseButton>
            </StyledDrawer>
          </>
        )}
      </NavbarContainer>
    </Wrapper>
  );
};

export default Navbar;

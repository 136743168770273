import { styled } from "@mui/system";

export const MainDiv = styled("div")(({ theme }) => ({
  margin: "0 100px 30px 100px",
  [theme.breakpoints.down("lg")]: {
    margin: "0 36px 30px 36px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 50px 30px 50px",
  },
  [theme.breakpoints.down(480)]: {
    margin: "0 40px 30px 40px",
  },
  [theme.breakpoints.down(400)]: {
    margin: "0 20px 30px 20px",
  },
}));

export const MainHead = styled("div")(({ theme }) => ({
  "& h1": {
    textAlign: "center",
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "64px",
    marginTop: 0,
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "39px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "32px",
    },
  },
}));

export const FirstDiv = styled("div")(({ theme }) => ({
  "& p": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "19px",
    color: "#212324",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "13px",
    },
  },
}));

export const SecondDiv = styled("div")(({ theme }) => ({
  marginTop: "50px",
  "& h2": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "35px",
    margin: 0,
    [theme.breakpoints.down(2000)]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "29px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "21px",
    },
  },
  "& h3": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "29px",
    margin: "10px 0",
    [theme.breakpoints.down(2000)]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "23px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "18px",
    },
  },
  "& p, & ul, & a": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "19px",
    color: "#212324",
    [theme.breakpoints.down(2000)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(353)]: {
      fontSize: "12px",
    },
  },
  "& ul": {
    marginTop: 0,
  },
  "& p": {
    margin: "10px 0",
  },
}));

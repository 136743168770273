import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";

export const FaqWrapper = styled("div")({
  marginTop: "10px",
  maxWidth: "2400px",
  margin: "0 auto",
  overflow: "hidden",
});

export const MainDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#e2f4ff",
  marginTop: "535px",
  display: "flex",
  alignItems: "center",
  padding: "64px 242px 64px 196px",
  [theme.breakpoints.down(2200)]: {
    marginTop: "362px",
  },
  [theme.breakpoints.down(2000)]: {
    marginTop: "419px",
  },
  [theme.breakpoints.down(1700)]: {
    marginTop: "236px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "37px 138px 37px 142px",
    marginTop: "300px",
  },
  [theme.breakpoints.down(1300)]: {
    marginTop: "185px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 108px 30px 66px",
    marginTop: "182px",
  },
  [theme.breakpoints.down(1000)]: {
    marginTop: "77px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "73px",
  },
  [theme.breakpoints.down(600)]: {
    marginTop: "62px",
  },
  [theme.breakpoints.down(480)]: {
    marginTop: "48px",
  },
  [theme.breakpoints.down(353)]: {
    marginTop: "26px",
  },
}));

export const FaqContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "30px",
  padding: "20px 70px 20px 70px",
  boxShadow: "4px 4px 15px 0px #0000001a",
  [theme.breakpoints.down(2000)]: {
    padding: "10px 40px 10px 40px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "5px 32px 5px 32px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "5px 18px 5px 18px",
  },
  [theme.breakpoints.down("md")]: {
    width: "464px",
    borderRadius: "15px",
  },
  [theme.breakpoints.down(600)]: {
    width: "382px",
  },
  [theme.breakpoints.down(480)]: {
    width: "323px",
  },
  [theme.breakpoints.down(400)]: {
    width: "257px",
  },
  [theme.breakpoints.down(315)]: {
    width: "211px",
  },
}));

export const FaqContent = styled("div")(({ theme }) => ({
  marginTop: "50px",
  [theme.breakpoints.down(2000)]: {
    marginTop: "25px",
  },
  [theme.breakpoints.down(400)]: {
    marginTop: "18px",
  },
  [theme.breakpoints.down(315)]: {
    marginTop: "12px",
  },
}));

export const Row = styled(Grid)(({ theme }) => ({
  marginBottom: "25px",
  display: "flex",
  alignItems: "stretch",
  [theme.breakpoints.down("lg")]: {
    marginBottom: "15px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const FaqHeader = styled("div")(({ theme }) => ({
  marginTop: "40px",
  [theme.breakpoints.down(2000)]: {
    marginTop: "18px",
  },
  [theme.breakpoints.down("xl")]: {
    marginTop: "12px",
  },
  "& h1": {
    margin: 0,
    marginLeft: "12px",
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "47px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "42px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "27px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "31px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down(480)]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "14px",
    },
  },
}));

export const baseQuestionsStyle = (theme) => ({
  borderRadius: "10px",
  boxShadow: "4px 4px 15px 0px #0000001a",
  padding: "20px 40px",
  [theme.breakpoints.between(1536, 2000)]: {
    padding: "20px",
  },
  [theme.breakpoints.between(1200, 1536)]: {
    padding: "15px 18px 20px 17px",
  },
  [theme.breakpoints.between(900, 1200)]: {
    padding: "13px 16px 10px 16px",
  },
  [theme.breakpoints.between(600, 900)]: {
    padding: "14px 24px 10px 24px",
  },
  [theme.breakpoints.between(480, 600)]: {
    padding: "14px 14px 10px 14px",
  },
  [theme.breakpoints.between(400, 480)]: {
    padding: "14px 14px 10px 14px",
  },
  [theme.breakpoints.between(353, 400)]: {
    padding: "14px 14px 10px 14px",
  },
  [theme.breakpoints.between(280, 353)]: {
    padding: "14px 14px 10px 14px",
  },
  "& h3": {
    margin: 0,
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 700,
    fontSize: "26px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "11px",
    },
  },
  "& p": {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 400,
    fontSize: "22px",
    margin: 0,
    marginTop: "9px",
    [theme.breakpoints.down(2000)]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down(1700)]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "13px",
      marginTop: "6px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down(315)]: {
      fontSize: "10px",
    },
  },
});

export const Questions = styled(motion.div)(({ theme }) => ({
  ...baseQuestionsStyle(theme),
}));

export const FixedHeightQuestions = styled(motion.div)(({ theme }) => ({
  ...baseQuestionsStyle(theme),
  height: "155px",
    [theme.breakpoints.down(2124)]: {
      height: "182px",
    },
    [theme.breakpoints.down(2000)]: {
      height: "123px",
    },
    [theme.breakpoints.down(1800)]: {
      height: "146px",
    },
    [theme.breakpoints.down("xl")]: {
      height: "127px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "121px",
    },
    [theme.breakpoints.down(999)]: {
      height: "140px",
    },
    [theme.breakpoints.down("md")]: {
      height: "94px",
    },
    [theme.breakpoints.down(600)]: {
      height: "112px",
    },
    [theme.breakpoints.down(480)]: {
      height: "127px",
    },
    [theme.breakpoints.down(400)]: {
      height: "106px",
    },
    [theme.breakpoints.down(315)]: {
      height: "114px",
    },
  })
);

export const FaqImg = styled(motion.div)(({ theme }) => ({
  "& img": {
    objectFit: "contain",
    width: "110%",
    height: "auto",
    marginTop: "-87%",
    marginLeft: "19%",
    [theme.breakpoints.down(2200)]: {
      marginTop: "-76%",
    },
    [theme.breakpoints.down(2000)]: {
      marginTop: "-82%",
      marginLeft: "17%",
    },
    [theme.breakpoints.down(1700)]: {
      marginTop: "-72%",
      marginLeft: "19%",
    },
    [theme.breakpoints.down("xl")]: {
      marginTop: "-72%",
      marginLeft: "17%",
    },
    [theme.breakpoints.down(1300)]: {
      marginTop: "-65%",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "-59%",
      marginLeft: "25%",
      width: "95%",
    },
    [theme.breakpoints.down(1000)]: {
      marginTop: "-47%",
      marginLeft: "26%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "0%",
      marginLeft: "0%",
      width: "100%",
    },
  },
}));

export const MdImg = styled(motion.div)(({ theme }) => ({
  position: "relative",
  "& img": {
    position: "absolute",
    objectFit: "contain",
    height: "auto",
    width: "30%",
    top: "-151px",
    right: "-25px",
    [theme.breakpoints.down(600)]: {
      top: "-132px",
      width: "32%",
    },
    [theme.breakpoints.down(480)]: {
      top: "-112px",
    },
    [theme.breakpoints.down(400)]: {
      top: "-99px",
      width: "36%",
    },
    [theme.breakpoints.down(353)]: {
      top: "-85px",
      width: "30%",
      right: "-20px",
    },
    [theme.breakpoints.down(315)]: {
      top: "-74px",
    },
  },
}));